import jQuery from 'jquery';

jQuery(function () {
  jQuery('a').click(function (e) {
    if (this.href.startsWith(location.href.replace(/#.*$/, '') + '#')) {
      e.preventDefault();
      var targetTop = jQuery(this.href.replace(/^.*#/, '#')).offset().top;
      jQuery('html,body').animate(
        {
          scrollTop: targetTop,
        },
        300,
      );
    }
  });

  jQuery('.side-nav a svg').click(function (e) {
    e.preventDefault();
    e.stopPropagation();
    jQuery(this).parent().parent().parent().toggleClass('collapsed');
    jQuery(this).parent().parent().parent().find('ul').slideToggle(200);
  });
});
