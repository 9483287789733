import jQuery from 'jquery';

jQuery(function () {
  jQuery('a').click(function (e) {
    if (this.href.startsWith(location.href.replace(/#.*$/, '') + '#')) {
      e.preventDefault();
      var targetTop = jQuery(this.href.replace(/^.*#/, '#')).offset().top;
      jQuery('html,body').animate(
        {
          scrollTop: targetTop,
        },
        300,
      );
    }
  });

  jQuery('.side-nav a svg').click(function (e) {
    e.preventDefault();
    e.stopPropagation();
    jQuery(this).parent().parent().parent().toggleClass('collapsed');
    jQuery(this).parent().parent().parent().find('ul').slideToggle(200);
  });
  jQuery('.side-nav>ul>li').addClass('collapsed').find('ul').hide();
});

jQuery(function ($) {
  $('#contact').submit(function (e) {
    var $form = $(this);
    e.preventDefault();
    $.post(
      '_send.php',
      $form.serialize(),
      function (data) {
        if (data.sent) {
          $form.html('Your request has been sent.');
        } else {
          $form.find('.error').html('There was an error. Please try again.');
        }
      },
      'json',
    );
  });
  $('#contact').each(function () {
    window.recaptchaLoaded = function () {
      if ($('#contact .recaptcha')[0])
        grecaptcha.render($('#contact .recaptcha')[0], {
          sitekey: window.recaptchaSiteKey,
        });
    };
    var recaptchaScript = document.createElement('script');
    recaptchaScript.src =
      'https://www.google.com/recaptcha/api.js?onload=recaptchaLoaded&render=explicit';
    document.body.appendChild(recaptchaScript);
  });
});
